* {
    font-family: 'Lato', sans-serif;
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
}
html {
  scroll-behavior: smooth;
}
body {
  background: #f5f5f5;
  width: 100%;
  margin: 0px;
  padding: 0;

  background: linear-gradient(to top left, #61a59a8f , #bdd9d5);
  overflow: hidden; 
  overflow-x: hidden;
  overflow-y: scroll;
}
background-container {
  display: block;
  overflow: hidden;
  height: 100%;
  width: 100%;
  padding: 100px 0 0 0;
}
h1, h2 {
  font-weight: 900;
  margin: 0px;
  padding: 0px;
}
p {
  font-size: 1.4em;
}
p + p {
  margin-top: 0.5em;
}
a {
  text-decoration: none;
  color: #4094CF;
  transition: all 0.3s;
  
  &:hover {
    color: #4094CF;
  }
}
ul {
    font-size: 1.4em;

    li {
      margin-left: 2em;
      padding: 0 0 0 1em;
      list-style-type: upper-roman;
    }
}