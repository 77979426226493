/**
 * index.scss
 * - Add any styles you want here!
 */
* {
  font-family: "Lato", sans-serif;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}
html {
  scroll-behavior: smooth;
}
body {
  background: #f5f5f5;
  width: 100%;
  margin: 0px;
  padding: 0;
  background: linear-gradient(to top left, #61a59a8f, #bdd9d5);
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: scroll;
}
background-container {
  display: block;
  overflow: hidden;
  height: 100%;
  width: 100%;
  padding: 100px 0 0 0;
}
h1, h2 {
  font-weight: 900;
  margin: 0px;
  padding: 0px;
}
p {
  font-size: 1.4em;
}
p + p {
  margin-top: 0.5em;
}
a {
  text-decoration: none;
  color: #4094CF;
  transition: all 0.3s;
}
a:hover {
  color: #4094CF;
}
ul {
  font-size: 1.4em;
}
ul li {
  margin-left: 2em;
  padding: 0 0 0 1em;
  list-style-type: upper-roman;
}
.background-animation {
  position: relative;
  display: block;
  z-index: 0;
  height: 100%;
  width: 100%;
}
.background-animation > span {
  pointer-events: none;
  width: 20vmin;
  height: 20vmin;
  border-radius: 20vmin;
  backface-visibility: hidden;
  position: absolute;
  animation-name: move;
  animation-duration: 6s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.background-animation > span:nth-child(1) {
  color: #2D4C83;
  top: 87%;
  left: 2%;
  animation-duration: 11.8s;
  animation-delay: -15.9s;
  transform-origin: 10vw 25vh;
  box-shadow: -40vmin 0 14.9805995215vmin currentColor;
}
.background-animation > span:nth-child(2) {
  color: #8E1B39;
  top: 67%;
  left: 41%;
  animation-duration: 14.9s;
  animation-delay: -13s;
  transform-origin: 16vw 6vh;
  box-shadow: 40vmin 0 12.5053782279vmin currentColor;
}
.background-animation > span:nth-child(3) {
  color: #dacbaf;
  top: 100%;
  left: 92%;
  animation-duration: 12.5s;
  animation-delay: -8.9s;
  transform-origin: 0vw 24vh;
  box-shadow: -40vmin 0 13.9362208787vmin currentColor;
}
.background-animation > span:nth-child(4) {
  color: #e9ca91;
  top: 56%;
  left: 55%;
  animation-duration: 11.9s;
  animation-delay: -11.1s;
  transform-origin: 11vw -1vh;
  box-shadow: 40vmin 0 5.286882835vmin currentColor;
}
.background-animation > span:nth-child(5) {
  color: #dacbaf;
  top: 59%;
  left: 35%;
  animation-duration: 10.5s;
  animation-delay: -5.3s;
  transform-origin: -6vw -1vh;
  box-shadow: 40vmin 0 8.8504045657vmin currentColor;
}
.background-animation > span:nth-child(6) {
  color: #8E1B39;
  top: 81%;
  left: 32%;
  animation-duration: 14.6s;
  animation-delay: -10.1s;
  transform-origin: -12vw -16vh;
  box-shadow: 40vmin 0 9.7078705711vmin currentColor;
}
.background-animation > span:nth-child(7) {
  color: #2D4C83;
  top: 53%;
  left: 71%;
  animation-duration: 14s;
  animation-delay: -6.2s;
  transform-origin: -5vw -14vh;
  box-shadow: -40vmin 0 7.0051710448vmin currentColor;
}
.background-animation > span:nth-child(8) {
  color: #3C4C3C;
  top: 10%;
  left: 2%;
  animation-duration: 13.9s;
  animation-delay: -7.3s;
  transform-origin: 5vw -7vh;
  box-shadow: -40vmin 0 13.1823779457vmin currentColor;
}
.background-animation > span:nth-child(9) {
  color: #2D4C83;
  top: 77%;
  left: 75%;
  animation-duration: 10.5s;
  animation-delay: -10.7s;
  transform-origin: -8vw -12vh;
  box-shadow: -40vmin 0 11.1995732153vmin currentColor;
}
.background-animation > span:nth-child(10) {
  color: #3C4C3C;
  top: 100%;
  left: 13%;
  animation-duration: 12.3s;
  animation-delay: -12.9s;
  transform-origin: -9vw -13vh;
  box-shadow: 40vmin 0 10.1609598962vmin currentColor;
}
.background-animation > span:nth-child(11) {
  color: #3C4C3C;
  top: 86%;
  left: 76%;
  animation-duration: 10.7s;
  animation-delay: -2.7s;
  transform-origin: -9vw -9vh;
  box-shadow: -40vmin 0 11.7604573681vmin currentColor;
}
.background-animation > span:nth-child(12) {
  color: #2D4C83;
  top: 54%;
  left: 30%;
  animation-duration: 12.5s;
  animation-delay: -4.4s;
  transform-origin: -4vw 16vh;
  box-shadow: -40vmin 0 12.0873832801vmin currentColor;
}
.background-animation > span:nth-child(13) {
  color: #8E1B39;
  top: 63%;
  left: 56%;
  animation-duration: 13.8s;
  animation-delay: -6.2s;
  transform-origin: 13vw -15vh;
  box-shadow: 40vmin 0 13.0292941783vmin currentColor;
}
.background-animation > span:nth-child(14) {
  color: #8E1B39;
  top: 77%;
  left: 95%;
  animation-duration: 11s;
  animation-delay: -14.4s;
  transform-origin: -12vw -16vh;
  box-shadow: -40vmin 0 5.6541466833vmin currentColor;
}
.background-animation > span:nth-child(15) {
  color: #dacbaf;
  top: 25%;
  left: 51%;
  animation-duration: 11.9s;
  animation-delay: -13.5s;
  transform-origin: 0vw 7vh;
  box-shadow: -40vmin 0 10.5127375411vmin currentColor;
}
.background-animation > span:nth-child(16) {
  color: #8E1B39;
  top: 15%;
  left: 81%;
  animation-duration: 11.3s;
  animation-delay: -14.2s;
  transform-origin: -19vw -24vh;
  box-shadow: 40vmin 0 9.1523235611vmin currentColor;
}
.background-animation > span:nth-child(17) {
  color: #dacbaf;
  top: 83%;
  left: 29%;
  animation-duration: 11.4s;
  animation-delay: -10.6s;
  transform-origin: -19vw 6vh;
  box-shadow: 40vmin 0 5.5381852937vmin currentColor;
}
.background-animation > span:nth-child(18) {
  color: #8E1B39;
  top: 87%;
  left: 57%;
  animation-duration: 11.5s;
  animation-delay: -0.4s;
  transform-origin: 24vw -10vh;
  box-shadow: -40vmin 0 5.0136968326vmin currentColor;
}
.background-animation > span:nth-child(19) {
  color: #8E1B39;
  top: 54%;
  left: 26%;
  animation-duration: 11.5s;
  animation-delay: -12.7s;
  transform-origin: 2vw -19vh;
  box-shadow: 40vmin 0 14.268041205vmin currentColor;
}
.background-animation > span:nth-child(20) {
  color: #2D4C83;
  top: 97%;
  left: 18%;
  animation-duration: 11.7s;
  animation-delay: -2.1s;
  transform-origin: -19vw -18vh;
  box-shadow: -40vmin 0 8.9428476775vmin currentColor;
}
@keyframes move {
  100% {
    transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}
.container {
  padding: 0em 1em;
}
.hide {
  display: none !important;
}
.btn {
  color: #000000;
  background: #fffcb6;
  background: -moz-linear-gradient(-45deg, #fffcb6 0%, #efd855 100%);
  background: -webkit-linear-gradient(-45deg, #fffcb6 0%, #efd855 100%);
  background: linear-gradient(165deg, #fffcb6 0%, #efd855 100%);
  background: linear-gradient(165deg, #b52a4e 0%, #72112b 100%);
  color: #fff;
  border-radius: 4px;
  box-shadow: 0px 7px 13px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 20px 2px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: all 0.3s;
}
.btn:hover {
  color: #ffffff;
  transform: rotate(-1deg);
  box-shadow: 0px 2px 30px 5px rgba(0, 0, 0, 0.6);
  transform: scale(1.05);
}
.btn-large {
  padding: 0.9375rem 2.5rem;
  font-size: 1em;
}
.btn-expand {
  font-size: 1.6em;
  margin: 0 auto;
}
p + .line {
  margin: 2em auto 0em auto;
}
.line {
  display: block;
  height: 8px;
  width: 80px;
  margin: 0 auto 3em auto;
}
.alert {
  display: block;
  background: #000;
  border-radius: 0.2em;
  padding: 0.2em;
}
.header {
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  background: #74918D;
  background: rgba(116, 145, 141, 0.97);
  background: #74918d;
  background: linear-gradient(90deg, #8e1b39 15%, rgba(142, 27, 57, 0.7263947815) 42%, rgba(110, 15, 40, 0.7123891793) 92%);
  background: linear-gradient(90deg, #74918d 15%, rgba(116, 145, 141, 0.7263947815) 42%, rgba(61, 91, 86, 0.7123891793) 92%);
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
  width: 100%;
  top: 0px;
  z-index: 10000;
  backdrop-filter: blur(20px);
}
.header .highlight {
  display: block;
  position: relative;
  text-align: center;
  background: #ffff00;
  cursor: pointer;
  transition: background 0.3s;
}
.header .highlight div {
  position: relative;
  display: block;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 1em 3.6em 1em 2em;
  font-size: 1.2em;
}
.header .highlight .btn-close {
  position: absolute;
  top: 50%;
  right: 1em;
  margin-top: -1.3em;
  display: inline-block;
  width: 2.6em;
  height: 2.6em;
  border: none;
  background: none;
  background: url("circle-xmark-regular.b5c1d3d7.svg");
  transition: all 0.3s;
}
.header .highlight:hover {
  background: #d8d800;
}
.header .highlight:hover .btn-close {
  background: url("circle-xmark-solid.97f69e55.svg");
}
.header .header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
}
.header .stretch {
  display: block;
  min-width: 0;
  max-width: 100%;
}
.header .logo {
  display: block;
  height: 96px;
  width: 94px;
  margin-left: 0.5em;
}
.header .logo img {
  display: block;
  height: 96px;
  width: 94px;
}
.header .navigation {
  display: block;
}
.header .navigation > a {
  font-size: 1.2em;
  padding: 0.7em 1em;
  margin: 0em 0.2em;
  line-height: 4.8em;
  color: #ffffff;
  height: 100%;
  border-radius: 0.2em;
  transition: all 0.3s;
}
.header .navigation > a:hover {
  background: rgba(255, 255, 255, 0.4);
}
.header .navigation > a.btn-register {
  color: #ffffff;
  margin-right: 1em;
  font-weight: 700;
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.4);
}
.header .navigation > a.btn-register:hover {
  background: linear-gradient(165deg, #b52a4e 0%, #72112b 100%);
  box-shadow: 0px 2px 20px 2px rgba(0, 0, 0, 0.6);
}
@media (max-width: 1000px) {
  .header .navigation > a {
    display: none;
  }
  .header .navigation > a.btn-register {
    display: inline;
  }
}
@media (min-width: 1120px) {
  .header .navigation > a {
    font-size: 1.4em;
    padding: 0.7em 1em;
    margin: 0em 0.2em;
    line-height: 4.2em;
  }
}
@media (min-width: 1120px) {
  .header .navigation > a {
    font-size: 1.4em;
    padding: 0.7em 1em;
    margin: 0em 0.2em;
    line-height: 4.2em;
  }
  .header .navigation > a.btn-register {
    margin-right: 0.7em;
  }
}
.wrapper {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
}
.container {
  display: block;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}
.cover-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  background: linear-gradient(70deg, white 0%, white 10%, rgba(255, 255, 255, 0.7002509622) 45%, rgba(142, 27, 57, 0.9019316345) 85%, rgba(142, 27, 57, 0.9523518025) 100%);
}
.cover-image-container {
  position: relative;
}
.cover-image {
  margin-top: -6em;
  display: block;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  min-height: 850px;
  transition: background-image 1s linear;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.cover-image[image=image0] {
  background-image: url("557A4203.c075298d.jpeg");
}
.cover-image[image=image1] {
  background-image: url("y8Rn0_48.64524536.jpeg");
}
.cover-image[image=image2] {
  background-image: url("M34woOgk.d2151e8f.jpeg");
}
.cover-image[image=image3] {
  background-image: url("2GRqe2Hg.927baa79.jpeg");
}
.cover-image[image=image4] {
  background-image: url("XVP_ELR-.fa29de52.jpeg");
}
.cover-image[image=image5] {
  background-image: url("A0Ud0j38.bb0c1416.jpeg");
}
.cover-image[image=image6] {
  background-image: url("aNf0E9K4.7761ba88.jpeg");
}
.cover-image[image=image7] {
  background-image: url("B3ZBTjXI.bc75147b.jpeg");
}
.cover-image[image=image8] {
  background-image: url("BHSxUTKB.2a5219ce.jpeg");
}
.cover-image[image=image9] {
  background-image: url("CLVMHEZE.b3b71236.jpeg");
}
.cover-image[image=image10] {
  background-image: url("d1jJf8SI.5a20c8a7.jpeg");
}
.cover-image[image=image11] {
  background-image: url("DEZHtBKA.e2eff8a7.jpeg");
}
.cover-image[image=image12] {
  background-image: url("DHE_XeKI.3265472e.jpeg");
}
.cover-image[image=image13] {
  background-image: url("DibDPqmI.408e413f.jpeg");
}
.cover-image[image=image14] {
  background-image: url("dtYsJnLU.2101899f.jpeg");
}
.cover-image[image=image15] {
  background-image: url("eZtotkxI.de8dd6b9.jpeg");
}
.cover-image[image=image16] {
  background-image: url("gsEOoB7Q.059cd596.jpeg");
}
.cover-image[image=image17] {
  background-image: url("GYnlYgOA.4f6d7eff.jpeg");
}
.cover-image[image=image18] {
  background-image: url("H8qqbAdF.233fb0c1.jpeg");
}
.cover-image[image=image19] {
  background-image: url("kaJDOGiA.1dcf27d0.jpeg");
}
.cover-image[image=image20] {
  background-image: url("kF_s8orY.820c8c3f.jpeg");
}
.cover-image[image=image21] {
  background-image: url("kNgwkl3U.bbdfce1e.jpeg");
}
.cover-image[image=image22] {
  background-image: url("m8FAji3N.66720903.jpeg");
}
.cover-image[image=image23] {
  background-image: url("M34woOgk.d2151e8f.jpeg");
}
.cover-image[image=image24] {
  background-image: url("Nfsoe_C6.537a2b10.jpeg");
}
.cover-image[image=image25] {
  background-image: url("Nun5IOos.4ee903eb.jpeg");
}
.cover-image[image=image26] {
  background-image: url("nxHY590o.31b10476.jpeg");
}
.cover-image[image=image27] {
  background-image: url("PObBEOQQ.b64fc897.jpeg");
}
.cover-image[image=image28] {
  background-image: url("pVnvbehY.1d4fa008.jpeg");
}
.cover-image[image=image29] {
  background-image: url("qLuchfpU.f84d581f.jpeg");
}
.cover-image[image=image30] {
  background-image: url("Umk6KG0E.46dc5bbd.jpeg");
}
.cover-image[image=image31] {
  background-image: url("vaMuPou8.38041a70.jpeg");
}
.cover-image[image=image32] {
  background-image: url("W23Ysa8R.d0e72a4d.jpeg");
}
.cover-image[image=image33] {
  background-image: url("XVP_ELR-.fa29de52.jpeg");
}
.cover-image[image=image34] {
  background-image: url("y8Rn0_48.64524536.jpeg");
}
.cover-image[image=image35] {
  background-image: url("ZL2AJBsJ.21568a69.jpeg");
}
.cover-image[image=image36] {
  background-image: url("zmlxfi-n.6648e963.jpeg");
}
.cover-image[image=image37] {
  background-image: url("Zpt_76vt.2b5f9968.jpeg");
}
@media (max-width: 600px) {
  .introduction {
    position: absolute;
    top: 0;
  }
  .introduction h1 {
    font-size: 4.6em;
    text-align: left;
    margin: 2em 0em 0em 0em;
    padding: 0;
    font-weight: 900;
    letter-spacing: -0.03em;
    font-size: 4.6em;
  }
  .introduction h1 span.hack-title {
    display: block;
    color: #4094CF;
    color: #74918D;
    letter-spacing: 0.1em;
    font-size: 0.4em;
    font-weight: 900;
    margin-bottom: 0.8em;
    font-size: 0.3em;
    line-height: 1em;
  }
  .introduction h1 span.hack-name {
    display: block;
    margin: 0;
    padding: 0;
    font-size: 0.7em;
    line-height: 1em;
  }
  .introduction p {
    font-size: 2.2em;
    font-weight: 400;
    display: block;
  }
  .introduction p + p {
    margin-top: 1em;
    font-size: 1.6em;
    max-width: 600px;
    width: 100%;
    font-weight: 600;
    font-style: italic;
  }
  .introduction a {
    margin-top: 1em;
    display: inline-block;
    font-size: 1.8em;
    padding: 0.8em 1.5em;
    border-radius: 0.4em;
    font-weight: 700;
  }
  .introduction .label-challenge {
    padding: 0em 0.2em;
    border-radius: 0.1em;
    background: #74918D;
    color: #ffffff;
    font-weight: 600;
    letter-spacing: 0.08em;
  }
  .introduction .label-deadline {
    margin-top: 0.4em;
    font-size: 1em;
    color: #74918D;
    color: #000000;
    font-weight: 500;
    max-width: 400px !important;
  }
}
@media (min-width: 601px) {
  .introduction {
    position: absolute;
    top: 0;
  }
  .introduction h1 {
    font-size: 4.6em;
    text-align: left;
    margin: 3em 0em 0em 0em;
    padding: 0;
    font-weight: 900;
    letter-spacing: -0.03em;
  }
  .introduction h1 span.hack-title {
    display: block;
    color: #4094CF;
    color: #74918D;
    letter-spacing: 0.1em;
    font-size: 0.4em;
    font-weight: 900;
    margin-bottom: 0.8em;
  }
  .introduction h1 span.hack-name {
    display: block;
    margin: 0;
    padding: 0;
  }
  .introduction p {
    font-size: 2.2em;
    font-weight: 400;
    display: block;
  }
  .introduction p + p {
    margin-top: 1em;
    font-size: 1.6em;
    max-width: 600px;
    width: 100%;
    font-weight: 600;
    font-style: italic;
  }
  .introduction a {
    margin-top: 1em;
    display: inline-block;
    font-size: 1.8em;
    padding: 0.8em 1.5em;
    border-radius: 0.4em;
    font-weight: 700;
  }
  .introduction .label-challenge {
    padding: 0em 0.2em;
    border-radius: 0.1em;
    background: #74918D;
    color: #ffffff;
    font-weight: 600;
    letter-spacing: 0.08em;
  }
  .introduction .label-deadline {
    margin-top: 0.4em;
    font-size: 1em;
    color: #74918D;
    color: #000000;
    font-weight: 500;
    max-width: 400px !important;
  }
}
.section-about-unleash {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.section-about-unleash .card {
  display: flex;
  flex-direction: column;
  width: 24.5%;
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 20px;
  border-radius: 12px;
  margin: 0px;
}
.section-people {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.section-people .card {
  display: block;
  position: relative;
  width: 300px;
  padding: 0.5em;
  transition: all 0.3s;
  top: 0;
  z-index: 2;
}
.section-people .card:nth-child(1) {
  transform: rotate(1deg);
}
.section-people .card:nth-child(2n+1) {
  transform: rotate(-2deg);
}
.section-people .card:nth-child(4n) {
  transform: rotate(1.5deg);
}
.section-people .card:hover {
  transform: rotate(0deg);
  transform: scale(1.1);
  z-index: 3;
}
.section-people figure {
  text-align: center;
  transition: all 0.5s ease;
  position: relative;
  overflow: hidden;
  height: 100%;
  border-radius: 0.8em;
  background: transparent;
  padding: 2.8em;
  box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.08);
  background: #8E1B39;
}
.section-people figure a {
  font-weight: 700;
  color: #ffffff;
  font-style: italic;
  border-bottom: 3px solid transparent;
}
.section-people figure .person-image {
  position: relative;
  display: inline-block;
  margin: 0 0 1.5em 0;
  border-radius: 50%;
  width: 217.63px;
  height: 217.63px;
  overflow: hidden;
  transition: all 0.5s ease;
  box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.2);
  background-color: #8E1B39;
}
.section-people figure .person-image img {
  position: relative;
  object-fit: cover;
  display: block;
  max-width: 100%;
  height: 100%;
  -webkit-filter: grayscale(100%) contrast(4);
  filter: grayscale(100%) contrast(4);
  mix-blend-mode: screen;
}
.section-people figure .person-image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all ease-in-out 0.5s;
  mix-blend-mode: multiply;
  background: linear-gradient(to top left, #3d5b56, #bdd9d5);
  background: linear-gradient(to top left, #74918D, #8E1B39);
  background: linear-gradient(to top left, #74918D, #fff5a7);
  background: linear-gradient(to top left, #8E1B39, #fff5a7);
}
.section-people figure .person-image .person-country {
  display: block;
  width: 100%;
  position: absolute;
  bottom: -2.5em;
  margin: 0 auto;
  text-align: center;
  color: white;
  z-index: 3;
  font-size: 2em;
  font-family: "Caveat", cursive;
  transform: rotate(-3deg);
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.8);
  transition: all 0.3s;
}
.section-people figure:hover {
  background: #ffffff;
  color: #000000;
  box-shadow: 0 2px 40px 2px rgba(150, 150, 150, 0.25);
  box-shadow: 0 2px 40px 5px rgba(86, 121, 116, 0.5);
}
.section-people figure:hover .person-image {
  background-color: #74918D;
  -webkit-filter: grayscale(50%) contrast(1);
  filter: grayscale(50%) contrast(1);
}
.section-people figure:hover .person-country {
  bottom: 0.5em;
}
.section-people figure:hover h2, .section-people figure:hover p {
  color: #000000;
}
.section-people figure:hover a {
  color: #4094CF;
}
.section-people figure:hover a:hover {
  border-bottom: 3px solid #631328;
}
.section-people figcaption {
  display: block;
  color: #919191;
  color: #ffffff;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.5px;
}
.section-people figcaption h2 {
  color: #ffffff;
  text-align: center;
  font-size: 1.6em;
}
.section-people figcaption p {
  display: block;
  text-align: center;
  margin-top: 0.8em;
  font-size: 1em;
  color: #ffffff;
}
.section-people figcaption p a {
  font-weight: 600;
}
.section-people figcaption p.title {
  color: #74918D;
  font-weight: 600;
  font-style: italic;
}
.section-organizers,
.section-facilitators,
.section-experts {
  align-items: center;
  flex-wrap: wrap;
}
.section-organizers .card,
.section-facilitators .card,
.section-experts .card {
  width: 300px;
  max-width: 100%;
  padding: 0.5em 0em;
  margin: 0 1em;
}
.section-organizers .card figure,
.section-facilitators .card figure,
.section-experts .card figure {
  width: 300px;
}
.section-experts .card figure {
  background: #2D4C83;
}
.section-experts .card figure:hover {
  background: #ffffff;
}
.section-experts .card figure .person-image {
  box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.2);
  background-color: #2D4C83;
}
.section-experts .card figure .person-image img {
  position: relative;
  object-fit: cover;
  display: block;
  max-width: 100%;
  height: 100%;
  -webkit-filter: grayscale(100%) contrast(4);
  filter: grayscale(100%) contrast(4);
  mix-blend-mode: screen;
}
.section-experts .card figure .person-image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all ease-in-out 0.5s;
  mix-blend-mode: multiply;
  background: linear-gradient(to top left, #3d5b56, #bdd9d5);
  background: linear-gradient(to top left, #74918D, #8E1B39);
  background: linear-gradient(to top left, #74918D, #fff5a7);
  background: linear-gradient(to top left, #4094CF, #fff5a7);
}
.section-experts .card figure .person-image .person-country {
  color: white;
}
.section-experts .card figure:hover {
  background: #ffffff;
  color: #000000;
  box-shadow: 0 2px 40px 2px rgba(150, 150, 150, 0.25);
  box-shadow: 0 2px 40px 5px rgba(86, 121, 116, 0.5);
}
.section-experts .card figure:hover .person-image {
  background-color: #74918D;
  -webkit-filter: grayscale(50%) contrast(1);
  filter: grayscale(50%) contrast(1);
}
.section-experts .card figure:hover .person-country {
  bottom: 0.5em;
}
.section-experts .card figure:hover h2, .section-experts .card figure:hover p {
  color: #000000;
}
.section-experts .card figure:hover a {
  color: #4094CF;
}
.section-experts .card figure:hover a:hover {
  border-bottom: 3px solid #631328;
}
h1 {
  margin: 0;
  padding: 2em 0em 0.5em 0em;
  font-size: 3.4em;
  font-weight: 500;
  text-align: center;
}
h2 {
  font-size: 2em;
  font-weight: 500;
  text-align: left;
}
.author {
  display: block;
  text-align: center;
  margin: 3em auto;
  color: #ffffff;
}
.chapter-about {
  background: #f9f9f9;
}
@media (max-width: 800px) {
  .list {
    display: block;
    width: 90%;
    margin: 0 auto 6em auto;
    transition: all 0.3s;
  }
  .list .list-item {
    display: block;
    width: 100%;
    margin-bottom: 0.2em;
    margin-bottom: 0.5em;
    transition: all 0.5s;
    z-index: 2;
  }
  .list .list-item.active {
    box-shadow: 0px 10px 30px 2px rgba(0, 0, 0, 0.4);
    box-shadow: 0 2px 40px 5px rgba(86, 121, 116, 0.5);
    border-radius: 0.6em;
    transform: scale(1.05);
    margin: 1.5em auto;
  }
  .list .list-item.active h2 button {
    border-radius: 0.4em 0.4em 0 0;
  }
  .list .list-item.active .list-item-content {
    display: block;
  }
  .list .list-item .list-item-content {
    display: none;
    width: 100%;
    transition: all 0.3s;
    padding: 2em;
    border-radius: 0 0 0.4em 0.4em;
    background: #ffffff;
    font-size: 0.8em;
  }
  .list .list-item h3.criteria {
    display: block;
    color: #ffffff;
    background: linear-gradient(165deg, #b52a4e 0%, #72112b 100%);
    padding: 0.5em;
    border-radius: 0.2em;
    margin: 1.5em 0 0.5em 0;
  }
  .list .list-item h3.criteria span {
    float: right;
  }
  .list .list-item .label-role {
    display: inline-block;
    white-space: nowrap;
    padding: 0.2em 0.4em;
    margin: 0 0.1em 0.25em;
    background: #bdd9d5;
    border-radius: 0.2em;
  }
  .list .list-item h2 {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .list .list-item h2 button {
    display: block;
    text-align: left;
    padding: 0;
    width: 100%;
    border: none;
    padding: 0.7em 1em;
    margin: 0;
    font-size: 0.6em;
    border-radius: 0.4em;
    background: linear-gradient(165deg, #b52a4e 0%, #72112b 100%);
    box-shadow: 0px 2px 10px 2px rgba(116, 145, 141, 0.6);
    color: #ffffff;
    transition: all 0.3s;
    cursor: pointer;
  }
  .list .list-item h2 button:hover {
    background: linear-gradient(165deg, #b52a4e 0%, #72112b 100%);
    color: #ffffff;
  }
}
@media (min-width: 801px) {
  .list {
    display: block;
    width: 80%;
    margin: 0 auto 6em auto;
  }
  .list .list-item {
    display: block;
    width: 100%;
    margin-bottom: 0.2em;
    margin-bottom: 0.5em;
    transition: all 0.5s;
    z-index: 2;
  }
  .list .list-item.active {
    box-shadow: 0px 10px 30px 2px rgba(0, 0, 0, 0.4);
    box-shadow: 0 2px 40px 5px rgba(86, 121, 116, 0.5);
    border-radius: 0.6em;
    transform: scale(1.05);
    margin: 1.5em auto;
  }
  .list .list-item.active h2 button {
    border-radius: 0.4em 0.4em 0 0;
  }
  .list .list-item.active .list-item-content {
    display: block;
  }
  .list .list-item .list-item-content {
    display: none;
    width: 100%;
    transition: all 0.3s;
    padding: 2em;
    border-radius: 0 0 0.4em 0.4em;
    background: #ffffff;
  }
  .list .list-item h3.criteria {
    display: block;
    color: #ffffff;
    background: linear-gradient(165deg, #b52a4e 0%, #72112b 100%);
    padding: 0.5em;
    border-radius: 0.2em;
    margin: 1.5em 0 0.5em 0;
  }
  .list .list-item h3.criteria span {
    float: right;
  }
  .list .list-item .label-role {
    display: inline-block;
    white-space: nowrap;
    padding: 0.2em 0.4em;
    margin: 0 0.1em 0.25em;
    background: #bdd9d5;
    border-radius: 0.2em;
  }
  .list .list-item h2 {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .list .list-item h2 button {
    display: block;
    text-align: left;
    padding: 0;
    width: 100%;
    border: none;
    padding: 0.7em 1em;
    margin: 0;
    font-size: 0.8em;
    border-radius: 0.4em;
    background: linear-gradient(165deg, #b52a4e 0%, #72112b 100%);
    box-shadow: 0px 2px 10px 2px rgba(116, 145, 141, 0.6);
    color: #ffffff;
    transition: all 0.3s;
    cursor: pointer;
  }
  .list .list-item h2 button:hover {
    background: linear-gradient(165deg, #b52a4e 0%, #72112b 100%);
    color: #ffffff;
  }
}
.container-heading {
  display: block;
  margin: 0 auto;
  max-width: 40em;
  text-align: center;
  padding-bottom: 3em;
}
.section-about {
  margin-top: 6em;
}
.section-footer {
  display: block;
  width: 100%;
  padding: 4em 0;
  background: #8E1B39;
}
.section-footer .logo {
  display: block;
  margin: 0 auto;
  height: 200px;
  width: 194px;
}
.section-footer .line {
  margin: 1em auto 0 auto;
}
.section-footer h1 {
  font-size: 1.8em;
  color: #ffffff;
  margin: 1.5em 0 0 0;
  padding: 0;
}
.section-footer nav {
  display: block;
  padding: 2em 0;
  margin: 0 auto;
  text-align: center;
}
.section-footer a,
.section-footer .author {
  display: inline-block;
  color: #ffffff;
  padding: 1em;
  margin: 0 auto 0.5em auto;
  transition: all 0.3s;
  border-radius: 0.2em;
}
.section-footer a:hover,
.section-footer .author:hover {
  background: rgba(255, 255, 255, 0.2);
}
.section-challenge {
  margin: -3em auto 0 auto;
  z-index: 2;
}
.section-challenge .challenge-container {
  display: block;
  position: relative;
  width: 100%;
  padding: 2em 0em;
  margin: 0em -2em 0em 0em;
  background: #ffffff;
  border-radius: 0.6em;
  box-shadow: 0px 2px 10px 2px rgba(116, 145, 141, 0.6);
}
.section-challenge .challenge-container h1 {
  margin: 0;
  padding: 1em 0;
  font-size: 2.7em;
  line-height: 1.2em;
}
.section-challenge h1 {
  margin: 0;
  padding: 1em 0;
  font-size: 1em;
  line-height: 1.2em;
  line-height: 2em;
  color: #000000;
}
.section-challenge h1 span.title-unleashhacks {
  display: block;
  font-weight: 900;
  font-size: 1.4em;
  color: #8E1B39;
  color: #74918D;
  margin: 0.5em 0 0 0;
}
.section-challenge h1 span.title-forthe {
  display: none;
  font-style: italic;
  font-size: 1.2em;
  margin: 0.5em 0 0 0;
}
.section-challenge h1 span.title-sdg {
  display: none;
  font-size: 1.5em;
  margin: 0 0 0.5em 0;
}
.section-challenge h1 span.title-forthe,
.section-challenge h1 span.title-sdg {
  display: inline-block;
  font-style: italic;
  font-size: 0.6em;
  margin: 0 0 0 0;
}
.section-challenge b {
  color: #8E1B39;
}
.section-challenge i > b {
  color: #000000;
}
.section-challenge a {
  transition: all 0.3s;
  border-bottom: 2px solid transparent;
}
.section-challenge a:hover {
  border-bottom: 2px solid #8E1B39;
}
.section-challenge .challenge-content {
  display: flex;
  flex-direction: column;
  line-height: 2em;
  column-rule-style: solid;
  column-rule-color: rgba(0, 0, 0, 0.1);
}
.section-challenge .challenge-content .challenge-column {
  display: block;
  width: 100%;
  padding: 0em 2em;
}
.section-challenge .challenge-content .challenge-column:first-child {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.section-challenge .challenge-content .challenge-column > h1:first-child {
  margin: 0;
}
.section-challenge .challenge-content .challenge-column img {
  width: 100%;
}
.section-challenge .challenge-content .ingress {
  background: #4094CF;
  background: #bdd9d5;
  padding: 1em;
  border-radius: 0.5em;
  margin: 0 0 1em 0;
}
.section-challenge .challenge-content ul {
  margin: 0.5em 0 1em 0;
}
.section-challenge .challenge-content p + p {
  margin-top: 1em;
}
.section-challenge .challenge-content h1 {
  margin: 0;
  padding: 0 0 0.5em 0;
  font-size: 2.7em;
  line-height: 1.2em;
}
.section-challenge .challenge-content h2 {
  margin: 1em 0 0.5em 0;
}
.section-challenge .challenge-content h2:first-child {
  margin: 0em 0 0.5em 0;
}
.section-challenge .challenge-content h3 {
  display: block;
  position: relative;
  padding: 0.5em 1em 0.5em 3em;
  background: #74918D;
  background: linear-gradient(165deg, #b52a4e 0%, #72112b 100%);
  border-radius: 0.2em;
  color: #ffffff;
}
.section-challenge .challenge-content h3 span {
  position: absolute;
  display: inline-block;
  width: 1.6em;
  height: 1.6em;
  left: 0.7em;
  margin: 0 1em 0 0;
  border-radius: 0.2em;
  background: #ffffff;
  border: 0.2em solid #fff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
}
.section-challenge .challenge-content h3.sdg3 {
  margin-top: 2em;
}
.section-challenge .challenge-content h3.sdg3 span {
  background: #279b48;
}
.section-challenge .challenge-content h3.sdg4 span {
  background: #c31f33;
}
.section-challenge .challenge-content h3.sdg11 span {
  background: #f99d26;
}
.section-challenge .sdg-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 75%;
  margin: 2em auto 3em auto;
  border-radius: 0.4em;
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.4);
  background: #ffffff;
  overflow: hidden;
  padding: 0.4em;
  transform: rotate(-1deg);
  transition: all 0.3s;
}
.section-challenge .sdg-container a {
  display: block;
  width: 25%;
  padding: 0;
  margin: 0;
}
.section-challenge .sdg-container a:hover {
  z-index: 2;
  transform: scale(1.1);
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.4);
  border: none;
}
.section-challenge .sdg-container a img {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
}
.section-challenge .sdg-container a:first-child {
  border-radius: 0.3em 0 0 0.3em;
}
.section-challenge .sdg-container a:last-child {
  border-radius: 0 0.3em 0.3em 0;
}
@media (max-width: 660px) {
  .section-challenge .sdg-container {
    width: 100% !important;
  }
}
@media (max-width: 660px) {
  .section-challenge .challenge-container {
    display: block;
    position: relative;
    width: 100%;
    padding: 2em 0em;
    margin: 0em -2em 0em 0em;
    background: #ffffff;
    border-radius: 0.6em;
    box-shadow: 0px 2px 10px 2px rgba(116, 145, 141, 0.6);
  }
  .section-challenge .challenge-container h1 {
    margin: 0;
    padding: 1em 0;
    font-size: 1.4em;
    line-height: 1.3em;
    line-height: 2em;
    color: #000000;
  }
  .section-challenge .challenge-container h1 span.title-unleashhacks {
    display: block;
    font-weight: 900;
    font-size: 3em;
    line-height: 0.85em;
    color: #8E1B39;
    color: #74918D;
    margin: 0.5em 0 0 0;
  }
  .section-challenge .challenge-container h1 span.title-forthe {
    display: none;
    font-style: italic;
    font-size: 1em;
    margin: 1em 0 0 0;
    padding: 0;
  }
  .section-challenge .challenge-container h1 span.title-sdg {
    display: none;
    font-size: 1em;
    margin: 1em 0 0.5em 0;
    padding: 0;
  }
  .section-challenge .challenge-container h1 span.title-forthe,
.section-challenge .challenge-container h1 span.title-sdg {
    display: block;
    font-style: italic;
    font-size: 1em;
    line-height: 0.5em;
  }
}
@media (min-width: 1000px) {
  .section-challenge {
    display: flex;
    width: 100%;
    z-index: 2;
    margin-top: -3em;
  }
  .section-challenge .challenge-container {
    display: block;
    position: relative;
    width: 100%;
    padding: 2em 0em;
    margin: 0em -2em 0em 0em;
    background: #ffffff;
    border-radius: 0.6em;
    box-shadow: 0px 2px 10px 2px rgba(116, 145, 141, 0.6);
  }
  .section-challenge .challenge-container h1 {
    margin: 0;
    padding: 1em 0;
    font-size: 1.4em;
    line-height: 1.2em;
    line-height: 2em;
    color: #000000;
  }
  .section-challenge .challenge-container h1 span.title-unleashhacks {
    display: block;
    font-weight: 900;
    font-size: 3em;
    color: #8E1B39;
    color: #74918D;
    margin: 0.5em 0 0 0;
  }
  .section-challenge .challenge-container h1 span.title-forthe {
    display: none;
    font-style: italic;
    font-size: 1.2em;
    margin: 0.5em 0 0 0;
  }
  .section-challenge .challenge-container h1 span.title-sdg {
    display: none;
    font-size: 1.5em;
    margin: 0 0 0.5em 0;
  }
  .section-challenge .challenge-container h1 span.title-forthe,
.section-challenge .challenge-container h1 span.title-sdg {
    display: inline-block;
    font-style: italic;
    font-size: 1.2em;
    margin: 0.3em 0 0 0;
  }
  .section-challenge .challenge-content {
    display: flex;
    flex-direction: row;
  }
  .section-challenge .challenge-content .challenge-column {
    display: block;
    width: 50%;
    padding: 0em 2em;
  }
  .section-challenge .challenge-content .challenge-column:first-child {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
  .section-challenge .challenge-content .challenge-column > h1:first-child {
    margin: 0;
  }
  .section-challenge .challenge-content .ingress {
    background: #4094CF;
    background: #bdd9d5;
    padding: 1em;
    border-radius: 0.5em;
    margin: 0 0 1em 0;
  }
  .section-challenge .challenge-content ul {
    margin: 0.5em 0 1em 0;
  }
  .section-challenge .challenge-content p + p {
    margin-top: 1em;
  }
  .section-challenge .challenge-content h1 {
    margin: 0;
    padding: 0 0 0.5em 0;
    font-size: 2.7em;
    line-height: 1.2em;
  }
  .section-challenge .challenge-content h2 {
    margin: 1em 0 0.5em 0;
  }
  .section-challenge .challenge-content h3 {
    display: block;
    position: relative;
    padding: 0.5em 1em 0.5em 3em;
    background: #74918D;
    background: linear-gradient(165deg, #b52a4e 0%, #72112b 100%);
    border-radius: 0.2em;
    color: #ffffff;
  }
  .section-challenge .challenge-content h3 span {
    position: absolute;
    display: inline-block;
    width: 1.6em;
    height: 1.6em;
    left: 0.7em;
    margin: 0 1em 0 0;
    border-radius: 0.2em;
    background: #ffffff;
    border: 0.2em solid #fff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
  }
  .section-challenge .challenge-content h3.sdg3 {
    margin-top: 2em;
  }
  .section-challenge .challenge-content h3.sdg3 span {
    background: #279b48;
  }
  .section-challenge .challenge-content h3.sdg4 span {
    background: #c31f33;
  }
  .section-challenge .challenge-content h3.sdg11 span {
    background: #f99d26;
  }
  .section-challenge .sdg-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 45%;
    width: 100%;
    margin: 2em auto 0 auto;
    border-radius: 0.4em;
    box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.4);
    background: #ffffff;
    overflow: hidden;
    padding: 0.4em;
    transform: rotate(-1deg);
    transition: all 0.3s;
  }
  .section-challenge .sdg-container a {
    display: block;
    width: 25%;
    padding: 0;
    margin: 0;
  }
  .section-challenge .sdg-container a:hover {
    z-index: 3;
    transform: scale(1.1);
    box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.4);
    border: none;
  }
  .section-challenge .sdg-container a img {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .section-challenge .sdg-container a:first-child {
    border-radius: 0.3em 0 0 0.3em;
  }
  .section-challenge .sdg-container a:last-child {
    border-radius: 0 0.3em 0.3em 0;
  }
}
@media (max-width: 900px) {
  .section-about {
    margin-top: 13em;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    z-index: 2;
  }
  .section-about p > b {
    white-space: nowrap;
  }
  .section-about .section-hacks {
    background: url("AlovqwwU.bb720843.jpeg") no-repeat;
    background-size: cover;
  }
  .section-about .section-plus {
    background: url("557A4203.c075298d.jpeg") no-repeat;
    background-size: cover;
  }
  .section-about .section-community,
.section-about .section-hacks,
.section-about .section-innovation-lab,
.section-about .section-plus {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
  }
  .section-about .section-community h1,
.section-about .section-hacks h1,
.section-about .section-innovation-lab h1,
.section-about .section-plus h1 {
    display: block;
    position: absolute;
    top: -4em;
    left: 0;
    right: 0;
    background: #4094CF;
    color: #fff;
    font-size: 1.8em;
    z-index: 2;
    padding: 1em;
    line-height: 1.4em;
    width: 100%;
  }
  .section-about .section-community .icon-detail,
.section-about .section-hacks .icon-detail,
.section-about .section-innovation-lab .icon-detail,
.section-about .section-plus .icon-detail {
    display: block;
    height: 8px;
    margin: 0em 0em 2em 0em;
    width: 80px;
    background: url("dark_line.c483bd69.svg") no-repeat left;
  }
  .section-about .section-hacks .grid-2,
.section-about .section-plus .grid-2 {
    background: rgba(0, 0, 0, 0.8) !important;
    color: #ffffff;
  }
  .section-about .section-plus .grid-2 {
    padding: 4.5em 4em 4em 4em;
  }
  .section-about .grid-1 {
    display: block;
    width: 100%;
    max-width: 100%;
    background: transparent url() no-repeat;
    background-size: cover;
    width: 100%;
  }
  .section-about .grid-2 {
    display: block;
    width: 100%;
    height: 100%;
    padding: 4.5em 4em 11em 4em;
    line-height: 1.55em;
    background: #ffffff;
    width: 100%;
  }
  .section-about .grid-2 h2 {
    padding: 0 0 1em 0;
    line-height: 1.4em;
  }
  .section-about .grid-2 p {
    font-size: 1.2em;
  }
  .section-about .grid-2 a {
    transition: all 0.3s;
    border-bottom: 0.2em solid transparent;
  }
  .section-about .grid-2 a:hover {
    border-bottom: 0.2em solid #8E1B39;
  }
  .section-about .image-community {
    background: url("https://unleash.org/wp-content/uploads/2020/05/SHM03695-760x505.jpg") no-repeat;
    background-size: cover;
  }
  .section-about .image-innovation-lab {
    background: url("https://unleash.org/wp-content/uploads/2020/05/SHM03695-760x505.jpg") no-repeat;
    background-size: cover;
  }
}
@media (min-width: 901px) {
  .section-about {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    z-index: 2;
  }
  .section-about p > b {
    white-space: nowrap;
  }
  .section-about .section-hacks {
    background: url("557A4203.c075298d.jpeg") no-repeat;
    background-size: cover;
  }
  .section-about .section-plus {
    background: url("PObBEOQQ.b64fc897.jpeg") no-repeat left;
    background-size: cover;
  }
  .section-about .section-community,
.section-about .section-hacks,
.section-about .section-innovation-lab,
.section-about .section-plus {
    position: relative;
    display: flex;
    flex-directioN: row;
    width: 100%;
    height: auto;
  }
  .section-about .section-community h1,
.section-about .section-hacks h1,
.section-about .section-innovation-lab h1,
.section-about .section-plus h1 {
    display: block;
    position: absolute;
    border-radius: 100em;
    background: #4094CF;
    color: #fff;
    font-size: 1.2em;
    z-index: 2;
    width: 8em;
    height: 8em;
    padding: 2.9em 0 0 0;
    top: 50%;
    left: 40%;
    margin: -4em 0 0 -4em;
  }
  .section-about .section-community .icon-detail,
.section-about .section-hacks .icon-detail,
.section-about .section-innovation-lab .icon-detail,
.section-about .section-plus .icon-detail {
    display: block;
    height: 8px;
    margin: 0em 0em 2em 0em;
    width: 80px;
    background: url("dark_line.c483bd69.svg") no-repeat left;
  }
  .section-about .section-hacks .grid-2,
.section-about .section-plus .grid-2 {
    background: rgba(0, 0, 0, 0.8) !important;
    color: #ffffff;
  }
  .section-about .grid-1 {
    display: block;
    width: 40%;
    max-width: 100%;
    min-height: 400px;
    background: transparent url() no-repeat;
    background-size: cover;
  }
  .section-about .grid-2 {
    display: block;
    width: 60%;
    height: auto;
    padding: 5em 8em;
    line-height: 1.55em;
    background: #ffffff;
  }
  .section-about .grid-2 h2 {
    padding: 0 0 1em 0;
    line-height: 1.4em;
  }
  .section-about .grid-2 p {
    font-size: 1.2em;
  }
  .section-about .grid-2 a {
    transition: all 0.3s;
    border-bottom: 0.2em solid transparent;
  }
  .section-about .grid-2 a:hover {
    border-bottom: 0.2em solid #8E1B39;
  }
  .section-about .image-community {
    background: url("vaMuPou8.38041a70.jpeg") no-repeat;
    background-size: cover;
  }
  .section-about .image-innovation-lab {
    background: url("qLuchfpU.f84d581f.jpeg") no-repeat;
    background-size: cover;
  }
}
@media (min-width: 1320px) {
  .section-community .grid-2,
.section-hacks .grid-2,
.section-innovation-lab .grid-2,
.section-plus .grid-2 {
    padding: 5em 20% 5em 8em;
  }
}
.events {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
}
.events .label {
  z-index: 3;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  font-weight: 700;
  background: #ffffff;
  color: #ffffff;
  padding: 0.5em 1em;
  border-radius: 0 0.5em 0 0.5em;
}
.events .label.label-upcoming {
  background: #4094CF;
}
.events .label.label-optional {
  background: #F2962A;
}
.events .label.label-closed {
  background: #74918D;
}
.events .event {
  display: flex;
  flex-direction: row;
  border-radius: 0.6em;
  background: rgba(255, 255, 255, 0.5);
  background: #ffffff;
  box-shadow: 0px 2px 10px 2px rgba(116, 145, 141, 0.6);
  margin: 0 0 0.5em 0;
  backdrop-filter: blur(20px);
  transition: all 0.3s;
}
.events .event.active {
  background: #fafafa;
  background: #ffffff;
}
.events .event.inactive {
  opacity: 0.25;
  background: #3C4C3C;
  color: #ffffff;
  box-shadow: inset 0px 0px 10px 2px rgba(0, 0, 0, 0.6);
}
.events .event.inactive .date {
  background: transparent;
}
.events .event.event-special .date {
  background: linear-gradient(165deg, #4094CF 0%, #2D4C83 100%);
}
.events .event .date {
  display: block;
  position: relative;
  width: 8em;
  min-width: 8em;
  background: linear-gradient(165deg, #b52a4e 0%, #72112b 100%);
  color: #ffffff;
  text-align: left;
  border-radius: 0.6em 0 0 0.6em;
}
.events .event .date .date-center {
  position: relative;
  padding: 1em;
}
.events .event .date .number {
  display: block;
  font-size: 1.8em;
  padding: 0;
  margin: 0;
  font-weight: 700;
}
.events .event .date .month {
  font-size: 0.8em;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: 300;
}
.events .event .activity {
  display: block;
  position: relative;
  padding: 1em;
  width: 100%;
}
.events .event .activity h3 {
  font-size: 1.8em;
}
.events .event .activity p {
  font-size: 1.1em;
}
p span.time-of-activity {
  display: inline-block;
  background: rgba(150, 150, 150, 0.2);
  padding: 0 0.1em;
  margin: 0 0.5em 0 0;
  border-radius: 0.2em;
  border: 1px solid #ccc;
}
iframe {
  margin-top: 1em;
}
.image-preloader {
  display: none;
}
.video-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  margin: 1em 0 2em 0;
}
.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: "";
}
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#slideshow #new-player.embed #player-toolbar {
  background: #ffffff !important;
}
.introduction a,
.navigation,
.card {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}
@media (min-width: 1400px) {
  #section-facilitators .container,
#section-experts .container,
#section-organizers .container {
    width: 100%;
    max-width: 1400px;
  }
}
@media (max-width: 940px) {
  .section-partners {
    z-index: 1;
    margin-top: -1em;
  }
  .section-partners .partners-container {
    display: block;
    position: relative;
    width: 100%;
    padding: 2em 2em;
    margin: 0em -2em 0em 0em;
    background: rgba(0, 0, 0, 0.8);
    background: #8E1B39;
    border-radius: 0 0 0.6em 0.6em;
    border-radius: 0.6em;
    box-shadow: 0 2px 10px 2px rgba(116, 145, 141, 0.6);
  }
  .section-partners .partners-container h2 {
    font-size: 1em;
    text-transform: uppercase;
    letter-spacing: 4px;
    text-align: center;
    margin: 0 0 1.5em 0;
    color: #ffffff;
    font-weight: 600;
  }
  .section-partners .partners-container .partner-box {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: stretch;
    align-content: center;
  }
  .section-partners .partners-container .partner-box a {
    display: block;
    width: 100%;
    max-height: 3.5em;
    margin: 0 auto 1.5em auto;
  }
  .section-partners .partners-container .partner-box a:last-child {
    margin: 0 auto 0 auto;
  }
  .section-partners .partners-container .partner-box img {
    display: block;
    max-width: 18em;
    height: auto;
    max-height: 4em;
    margin: 0 auto;
    filter: grayscale(100%);
    filter: brightness(0) invert(1);
  }
}
@media (min-width: 941px) {
  .section-partners {
    z-index: 1;
    margin-top: -1em;
  }
  .section-partners .partners-container {
    display: block;
    position: relative;
    width: 100%;
    padding: 2em 2em;
    margin: 0em -2em 0em 0em;
    background: rgba(0, 0, 0, 0.8);
    background: #8E1B39;
    border-radius: 0 0 0.6em 0.6em;
    border-radius: 0.6em;
    box-shadow: 0 2px 10px 2px rgba(116, 145, 141, 0.6);
  }
  .section-partners .partners-container h2 {
    font-size: 1em;
    text-transform: uppercase;
    letter-spacing: 4px;
    text-align: center;
    margin: 0 0 1.5em 0;
    color: #ffffff;
    font-weight: 600;
  }
  .section-partners .partners-container .partner-box {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: stretch;
    align-content: center;
  }
  .section-partners .partners-container .partner-box a {
    display: block;
    width: 33.333%;
    margin: 0 auto;
  }
  .section-partners .partners-container .partner-box img {
    display: block;
    max-width: 18em;
    height: auto;
    max-height: 3.5em;
    margin: 0 auto;
    filter: grayscale(100%);
    filter: brightness(0) invert(1);
  }
}
/*# sourceMappingURL=index.08c0eb0e.css.map */
