/**
 * index.scss
 * - Add any styles you want here!
 */
 @import 'global';
 @import 'animations';
 
 
 .container {
   padding: 0em 1em;
 }

 .hide {
   display: none !important;
 }
 
 .btn {
   color: #000000;
   background: rgb(255,252,182);
   background: -moz-linear-gradient(-45deg, rgba(255,252,182,1) 0%, rgba(239,216,85,1) 100%);
   background: -webkit-linear-gradient(-45deg, rgba(255,252,182,1) 0%,rgba(239,216,85,1) 100%);
   background: linear-gradient(165deg, rgba(255,252,182,1) 0%,rgba(239,216,85,1) 100%);
   
   background: linear-gradient(165deg, #b52a4e 0%, #72112b 100%);
   color: #fff;
   border-radius: 4px;
   box-shadow: 0px 7px 13px 0px rgb(0 0 0 / 10%);
   box-shadow: 0px 2px 20px 2px rgba(0,0,0,0.3);
   cursor: pointer;
   transition: all 0.3s;

   &:hover {
    color: #ffffff;
    transform: rotate(-1deg);
    box-shadow: 0px 2px 30px 5px rgba(0,0,0, 0.6);
    transform: scale(1.05);
   }
 }
 .btn-large {
   padding: 0.9375rem 2.5rem;
   font-size: 1em;
 }
 .btn-expand {
   font-size: 1.6em;
   margin: 0 auto;
 }
 p + .line {
  margin: 2em auto 0em auto;
 }
 .line {
  display: block;
  height: 8px;
  width: 80px;
  margin: 0 auto 3em auto;
 }



 .alert {
  display: block;
  background: #000;
  border-radius: 0.2em;
  padding: 0.2em;
 }
 
 .header{
   position: fixed;
   width: 100%;
   //height: 6em;

  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;


   background: #74918D;
   background: rgba(116, 145, 141, 0.97);
   
   background: rgb(116,145,141);
   background: linear-gradient(90deg, rgba(142,27,57,1) 15%, rgba(142,27,57,0.7263947815454307) 42%, rgba(110,15,40,0.7123891793045343) 92%);
   background: linear-gradient(90deg, rgba(116,145,141,1) 15%, rgba(116,145,141,0.7263947815454307) 42%, rgba(61,91,86,0.7123891793045343) 92%);
   
   box-shadow: 0 0 3px 1px rgba(0,0,0,0.2);
   width: 100%;
   top: 0px;

   z-index: 10000;
   backdrop-filter: blur(20px);

  .highlight {
    display: block;
    position: relative;
    text-align: center;
    background: #ffff00;
    cursor: pointer;
    transition: background 0.3s;

    div {
      position: relative;
      display: block;
      max-width: 1280px;
      width: 100%;
      margin: 0 auto;
      padding: 1em 3.6em 1em 2em;
      font-size: 1.2em;
    }
    .btn-close {
      position: absolute;
      top: 50%;
      right: 1em;
      margin-top: -1.3em;
      display: inline-block;
      width: 2.6em;
      height: 2.6em;
      border: none;
      background: none;
      background: url(../assets/images/circle-xmark-regular.svg);
      transition: all 0.3s;
    }
    &:hover {
      background: #d8d800;
      .btn-close {
        background: url(../assets/images/circle-xmark-solid.svg);
      }
    }
  }
   
   .header-container {
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     max-width: 1280px;
     width: 100%;
     margin: 0 auto;
   }
   
   .stretch {
     display: block;
     min-width: 0;
     max-width: 100%;
   }
   .logo {
     display: block;
     height: 96px;
     width: 94px;
     margin-left: 0.5em;

     img {
      display: block;
      height: 96px;
      width: 94px;
      //top: 0;
      //position: absolute;
    }
   }
   .navigation {
      display: block;    
      & > a {
        font-size: 1.2em;
        padding: 0.7em 1em; 
        margin: 0em 0.2em;
        line-height: 4.8em;
        
        color: #ffffff; 
        height: 100%;
        border-radius: 0.2em;
        transition: all 0.3s;
      &:hover {
        background: rgba(255,255,255,0.4);
      }
      &.btn-register {
        color: #ffffff;
        margin-right: 1em;
        font-weight: 700;
        box-shadow: 0px 2px 10px 2px rgba(0,0,0,0.4);
        &:hover {
          background: linear-gradient(165deg, #b52a4e 0%, #72112b 100%);
          box-shadow: 0px 2px 20px 2px rgba(0,0,0,0.6);
        }
      }
    }
  }
}
@media (max-width: 1000px) {
  .header{
    .navigation {
      & > a {
        display: none;
      }
      & > a.btn-register {
        display: inline;
      }
    }
  }
}
@media (min-width: 1120px) {
  .header{
    .navigation {
      & > a {
        font-size: 1.4em;
        padding: 0.7em 1em; 
        margin: 0em 0.2em;
        line-height: 4.2em;
        &.btn-register {

        }
      }
    }
  }
}
@media (min-width: 1120px) {
  .header{
    .navigation {
      & > a {
        font-size: 1.4em;
        padding: 0.7em 1em; 
        margin: 0em 0.2em;
        line-height: 4.2em;
        &.btn-register {
          margin-right: 0.7em;
        }
      }
    }
  }
}

.wrapper {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
}
.container {
  display: block;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}
.cover-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(255,255,255);
  background: linear-gradient(70deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 10%, rgba(255,255,255,0.7002509622012867) 45%, rgba(142,27,57,0.9019316344701943) 85%, rgba(142,27,57,0.9523518025374212) 100%);
}
.cover-image-container {
  position: relative;
}
.cover-image {
  margin-top: -6em;
  display: block;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  min-height: 850px;
  transition: background-image 1s linear;
  background-repeat: no-repeat;
  background-position: center;
  &[image="image0"] { background-image: url('../assets/images/marketing/557A4203.jpeg'); }
  &[image="image1"] { background-image: url('../assets/images/marketing/y8Rn0_48.jpeg'); }
  &[image="image2"] { background-image: url('../assets/images/marketing/M34woOgk.jpeg'); }
  &[image="image3"] { background-image: url('../assets/images/marketing/2GRqe2Hg.jpeg'); }
  &[image="image4"] { background-image: url('../assets/images/marketing/XVP_ELR-.jpeg'); }
  &[image="image5"] { background-image: url('../assets/images/marketing/A0Ud0j38.jpeg'); }
  &[image="image6"] { background-image: url('../assets/images/marketing/aNf0E9K4.jpeg'); }
  &[image="image7"] { background-image: url('../assets/images/marketing/B3ZBTjXI.jpeg') }
  &[image="image8"] { background-image: url('../assets/images/marketing/BHSxUTKB.jpeg') }
  &[image="image9"] { background-image: url('../assets/images/marketing/CLVMHEZE.jpeg') }
  &[image="image10"] { background-image: url('../assets/images/marketing/d1jJf8SI.jpeg') }
  &[image="image11"] { background-image: url('../assets/images/marketing/DEZHtBKA.jpeg') }
  &[image="image12"] { background-image: url('../assets/images/marketing/DHE_XeKI.jpeg') }
  &[image="image13"] { background-image: url('../assets/images/marketing/DibDPqmI.jpeg') }
  &[image="image14"] { background-image: url('../assets/images/marketing/dtYsJnLU.jpeg') }
  &[image="image15"] { background-image: url('../assets/images/marketing/eZtotkxI.jpeg') }
  &[image="image16"] { background-image: url('../assets/images/marketing/gsEOoB7Q.jpeg') }
  &[image="image17"] { background-image: url('../assets/images/marketing/GYnlYgOA.jpeg') }
  &[image="image18"] { background-image: url('../assets/images/marketing/H8qqbAdF.jpeg') }
  &[image="image19"] { background-image: url('../assets/images/marketing/kaJDOGiA.jpeg') }
  &[image="image20"] { background-image: url('../assets/images/marketing/kF_s8orY.jpeg') }
  &[image="image21"] { background-image: url('../assets/images/marketing/kNgwkl3U.jpeg') }
  &[image="image22"] { background-image: url('../assets/images/marketing/m8FAji3N.jpeg') }
  &[image="image23"] { background-image: url('../assets/images/marketing/M34woOgk.jpeg') }
  &[image="image24"] { background-image: url('../assets/images/marketing/Nfsoe_C6.jpeg') }
  &[image="image25"] { background-image: url('../assets/images/marketing/Nun5IOos.jpeg') }
  &[image="image26"] { background-image: url('../assets/images/marketing/nxHY590o.jpeg') }
  &[image="image27"] { background-image: url('../assets/images/marketing/PObBEOQQ.jpeg') }
  &[image="image28"] { background-image: url('../assets/images/marketing/pVnvbehY.jpeg') }
  &[image="image29"] { background-image: url('../assets/images/marketing/qLuchfpU.jpeg') }
  &[image="image30"] { background-image: url('../assets/images/marketing/Umk6KG0E.jpeg') }
  &[image="image31"] { background-image: url('../assets/images/marketing/vaMuPou8.jpeg') }
  &[image="image32"] { background-image: url('../assets/images/marketing/W23Ysa8R.jpeg') }
  &[image="image33"] { background-image: url('../assets/images/marketing/XVP_ELR-.jpeg') }
  &[image="image34"] { background-image: url('../assets/images/marketing/y8Rn0_48.jpeg') }
  &[image="image35"] { background-image: url('../assets/images/marketing/ZL2AJBsJ.jpeg') }
  &[image="image36"] { background-image: url('../assets/images/marketing/zmlxfi-n.jpeg') }
  &[image="image37"] { background-image: url('../assets/images/marketing/Zpt_76vt.jpeg') }
  background-size: cover;
}
@media (max-width: 600px) { 
  .introduction {
    position: absolute;
    top: 0;
    h1 {
      font-size: 4.6em;
      text-align: left;
      margin: 2em 0em 0em 0em;
      padding: 0;
      font-weight: 900;
      letter-spacing: -0.03em;

      font-size: 4.6em;
      
      span.hack-title {
        display: block;
        color: #4094CF;
        color: #74918D;
        letter-spacing: 0.1em;
        font-size: 0.4em;
        font-weight: 900;
        margin-bottom: 0.8em;

        font-size: 0.3em;
        line-height: 1em;
      }
      span.hack-name {
        display: block;
        margin: 0;
        padding: 0;

        font-size: 0.7em;
        line-height: 1em;
      }
    }
    p {
      font-size: 2.2em;
      font-weight: 400;
      display: block;
    }
    p + p {
      margin-top: 1em;
      font-size: 1.6em;
      max-width: 600px;
      width: 100%;
      font-weight: 600;
      font-style: italic;
    }
    a {
      margin-top: 1em;
      display: inline-block;
      font-size: 1.8em;
      padding: 0.8em 1.5em;
      border-radius: 0.4em;
      font-weight: 700;
    }
    .label-challenge {
      padding: 0em 0.2em;
      border-radius: 0.1em;
      background: #74918D;
      color: #ffffff;
      font-weight: 600;
      letter-spacing: 0.08em;
    }
    .label-deadline {
      margin-top: 0.4em;
      font-size: 1em;
      color: #74918D;
      color:#000000;
      font-weight: 500;
      max-width: 400px !important;
    }
  }
}
@media (min-width: 601px) {
  .introduction {
    position: absolute;
    top: 0;
    h1 {
      font-size: 4.6em;
      text-align: left;
      margin: 3em 0em 0em 0em;
      padding: 0;
      font-weight: 900;
      letter-spacing: -0.03em;
      
      span.hack-title {
        display: block;
        color: #4094CF;
        color: #74918D;
        letter-spacing: 0.1em;
        font-size: 0.4em;
        font-weight: 900;
        margin-bottom: 0.8em;
      }
      span.hack-name {
        display: block;
        margin: 0;
        padding: 0;
      }
    }
    p {
      font-size: 2.2em;
      font-weight: 400;
      display: block;
    }
    p + p {
      margin-top: 1em;
      font-size: 1.6em;
      max-width: 600px;
      width: 100%;
      font-weight: 600;
      font-style: italic;
    }
    a {
      margin-top: 1em;
      display: inline-block;
      font-size: 1.8em;
      padding: 0.8em 1.5em;
      border-radius: 0.4em;
      font-weight: 700;
    }
    .label-challenge {
      padding: 0em 0.2em;
      border-radius: 0.1em;
      background: #74918D;
      color: #ffffff;
      font-weight: 600;
      letter-spacing: 0.08em;
    }
    .label-deadline {
      margin-top: 0.4em;
      font-size: 1em;
      color: #74918D;
      color:#000000;
      font-weight: 500;
      max-width: 400px !important;
    }
  }
}

 
.section-about-unleash {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  
  .card {
    display: flex;
    flex-direction: column;
    width: 24.5%;
    background: transparent;
    border: 1px solid rgba(0,0,0,0.15);
    padding: 20px;
    border-radius: 12px;
    margin: 0px;
  }
}


.section-people {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
    
  .card {
    display: block;
    position: relative;
    width: 300px;
    padding: 0.5em;
    transition: all 0.3s;
    top: 0;
    z-index: 2;
    
    &:nth-child(1) {
      transform: rotate(1deg);
    }
    &:nth-child(2n + 1) {
      transform: rotate(-2deg);
    }
    &:nth-child(4n) {
      transform: rotate(1.5deg);
    }
    &:hover {
      transform: rotate(0deg);
      //top: -10px;
      transform: scale(1.1);
      z-index: 3;
    }
  }

  figure {
  text-align: center;
  transition: all .5s ease;
  position: relative;
  overflow: hidden;
  height: 100%; 
  border-radius: 0.8em;
  background: transparent;
  padding: 2.8em;
  box-shadow: 0px 5px 10px 2px rgba(0,0,0,0.2);
  background: rgba(0,0,0,0.08);
  background: #8E1B39;
  a {
    font-weight: 700;
    color: #ffffff;
    font-style: italic;
    border-bottom: 3px solid transparent;
  }
    .person-image {
      position: relative;
      display: inline-block;
      margin: 0 0 1.5em 0;
      border-radius: 50%;
      width: 217.63px;
      height: 217.63px;
      overflow: hidden;
      transition: all .5s ease;
      box-shadow: 0px 2px 3px 2px rgba(0,0,0,0.2);
      background-color: #8E1B39;
      img {
        position: relative;
        
        object-fit: cover;
        display: block;
        max-width: 100%;
        height: 100%; 
      -webkit-filter: grayscale(100%) contrast(4);
      filter: grayscale(100%) contrast(4);
      mix-blend-mode: screen;
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all ease-in-out .5s;
        mix-blend-mode: multiply;
        background: linear-gradient(to top left, #3d5b56 , #bdd9d5);
        background: linear-gradient(to top left, #74918D , #8E1B39);
        background: linear-gradient(to top left, #74918D , #fff5a7);
        background: linear-gradient(to top left, #8E1B39 , #fff5a7);
      }
      .person-country {
        display: block;
        width: 100%;
        position: absolute;
        bottom: -2.5em;
        margin: 0 auto;
        text-align: center;
        color: rgba(255,255,255,1);
        z-index: 3;
        font-size: 2em;
        font-family: 'Caveat', cursive;
        transform: rotate(-3deg);
        text-shadow: 0px 1px 3px rgba(0,0,0,0.8);
        transition: all 0.3s;
      }
    }
    &:hover {
      background: #ffffff;
      color: #000000;
      box-shadow: 0 2px 40px 2px rgba(150,150,150,0.25);
      box-shadow: 0 2px 40px 5px rgba(86,121,116,0.5);
      .person-image {
          background-color: #74918D;
          -webkit-filter: grayscale(50%) contrast(1);
          filter: grayscale(50%) contrast(1);
      }
      .person-country {
        bottom: 0.5em;
      }
      h2, p {
        color: #000000;
      }
      a {
      color: #4094CF;
      &:hover {
        border-bottom: 3px solid darken(#8E1B39,10%);
      }
    }
    }
  }
  figcaption {
    display: block;
    color: #919191;
    color: #ffffff;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.5px;
    
    h2 {
      color: #ffffff;
      text-align: center;
      font-size: 1.6em;
    }
    p {
      display: block;
      text-align: center;
      margin-top: 0.8em;
      font-size: 1em;
      color: #ffffff;
      a {
        font-weight: 600;
      }
      &.title {
        color: #74918D;
        font-weight: 600;
        font-style: italic;
      }
    }
  }
}


.section-organizers,
.section-facilitators,
.section-experts {
  align-items: center;
  flex-wrap: wrap;
  .card {
    width: 300px;
    max-width: 100%;
    padding: 0.5em 0em;
    margin: 0 1em;

    figure {
      width: 300px;
    }
  }
}
.section-experts {
  .card {
    figure {
      background: #2D4C83;
      &:hover {
        background: #ffffff;
      }

      .person-image {
        box-shadow: 0px 2px 3px 2px rgba(0,0,0,0.2);
        background-color: #2D4C83;
        img {
          position: relative;
          object-fit: cover;
          display: block;
          max-width: 100%;
          height: 100%; 
          -webkit-filter: grayscale(100%) contrast(4);
          filter: grayscale(100%) contrast(4);
          mix-blend-mode: screen;
        }
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transition: all ease-in-out .5s;
          mix-blend-mode: multiply;
          background: linear-gradient(to top left, #3d5b56 , #bdd9d5);
          background: linear-gradient(to top left, #74918D , #8E1B39);
          background: linear-gradient(to top left, #74918D , #fff5a7);
          background: linear-gradient(to top left, #4094CF , #fff5a7);
        }
        .person-country {
          color: rgba(255,255,255,1);
        }
      }
      &:hover {
        background: #ffffff;
        color: #000000;
        box-shadow: 0 2px 40px 2px rgba(150,150,150,0.25);
        box-shadow: 0 2px 40px 5px rgba(86,121,116,0.5);
        .person-image {
            background-color: #74918D;
            -webkit-filter: grayscale(50%) contrast(1);
            filter: grayscale(50%) contrast(1);
        }
        .person-country {
          bottom: 0.5em;
        }
        h2, p {
          color: #000000;
        }
        a {
          color: #4094CF;
          &:hover {
            border-bottom: 3px solid darken(#8E1B39,10%);
          }
        }
      }
    }
  }
}


 h1 {
   margin: 0;
   padding: 2em 0em 0.5em 0em;
   font-size: 3.4em;
   font-weight: 500;
   text-align: center;
 }
 h2 {
   font-size: 2em;
   font-weight: 500;
   text-align: left;
 }
 .author {
    display: block;
    text-align: center;
    margin: 3em auto;
    color: #ffffff;
 }
 
 
 .chapter-about {
   background: #f9f9f9;
 }
 
 




@media (max-width: 800px) {
  .list {
    display: block;
    width: 90%;
    margin: 0 auto 6em auto;
    transition: all 0.3s;
  
    .list-item {
      display: block;
      width: 100%;
      margin-bottom: 0.2em;
      margin-bottom: 0.5em;
      transition: all 0.5s;
      z-index: 2;
      &.active {
      box-shadow: 0px 10px 30px 2px rgb(0 0 0 / 40%);
      box-shadow: 0 2px 40px 5px rgb(86 121 116 / 50%);
      border-radius: 0.6em;
      transform: scale(1.05);
      margin: 1.5em auto;
  
        h2 {
          button {
          border-radius: 0.4em 0.4em 0 0;
          }
        }
        .list-item-content {
          display: block;
        }
      }
      .list-item-content {
      display: none;
      width: 100%;
      transition: all 0.3s;
      padding: 2em;
      border-radius: 0 0 0.4em 0.4em;
      background: #ffffff;
      font-size: 0.8em;
    }
    h3.criteria {
      display: block;
      color: #ffffff;
      background: linear-gradient(165deg, rgb(181, 42, 78) 0%, rgb(114, 17, 43) 100%);
      padding: 0.5em;
      border-radius: 0.2em;
      margin: 1.5em 0 0.5em 0;
      span {
        float: right;
      }
    }
    .label-role {
      display: inline-block;
      white-space: nowrap;
      padding: 0.2em 0.4em;
      margin: 0 0.1em 0.25em;
      background: #bdd9d5;
      border-radius: 0.2em;
    }
      h2 {
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
        button {
          display: block;
          text-align: left;
          padding: 0;
          width: 100%;
          border: none;
          padding: 0.7em 1em;
          margin: 0;
          font-size: 0.6em;
          border-radius: 0.4em;
          background: linear-gradient(165deg, #b52a4e 0%, #72112b 100%);
          box-shadow: 0px 2px 10px 2px rgba(116, 145, 141, 0.6);
          color: #ffffff;
          transition: all 0.3s;
          cursor: pointer;
          &:hover {
          background: linear-gradient(165deg, #b52a4e 0%, #72112b 100%);
          color: #ffffff;
          }
        }
      }
    }
  }
}

@media (min-width: 801px) {
  .list {
    display: block;
    width: 80%;
    margin: 0 auto 6em auto;
  
    .list-item {
      display: block;
      width: 100%;
      margin-bottom: 0.2em;
      margin-bottom: 0.5em;
      transition: all 0.5s;
      z-index: 2;
      &.active {
      box-shadow: 0px 10px 30px 2px rgb(0 0 0 / 40%);
      box-shadow: 0 2px 40px 5px rgb(86 121 116 / 50%);
      border-radius: 0.6em;
      transform: scale(1.05);
      margin: 1.5em auto;
  
        h2 {
          button {
          border-radius: 0.4em 0.4em 0 0;
          }
        }
        .list-item-content {
          display: block;
        }
      }
      .list-item-content {
      display: none;
      width: 100%;
      transition: all 0.3s;
      padding: 2em;
      border-radius: 0 0 0.4em 0.4em;
      background: #ffffff;
    }
    h3.criteria {
      display: block;
      color: #ffffff;
      background: linear-gradient(165deg, rgb(181, 42, 78) 0%, rgb(114, 17, 43) 100%);
      padding: 0.5em;
      border-radius: 0.2em;
      margin: 1.5em 0 0.5em 0;
      span {
        float: right;
      }
    }
    .label-role {
      display: inline-block;
      white-space: nowrap;
      padding: 0.2em 0.4em;
      margin: 0 0.1em 0.25em;
      background: #bdd9d5;
      border-radius: 0.2em;
    }
      h2 {
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
        button {
          display: block;
          text-align: left;
          padding: 0;
          width: 100%;
          border: none;
          padding: 0.7em 1em;
          margin: 0;
          font-size: 0.8em;
          border-radius: 0.4em;
          background: linear-gradient(165deg, #b52a4e 0%, #72112b 100%);
          box-shadow: 0px 2px 10px 2px rgba(116, 145, 141, 0.6);
          color: #ffffff;
          transition: all 0.3s;
          cursor: pointer;
          &:hover {
          background: linear-gradient(165deg, #b52a4e 0%, #72112b 100%);
          color: #ffffff;
          }
        }
      }
    }
  }
}
 
 


.container-heading {
  display: block;
  margin: 0 auto;
  max-width: 40em;
  text-align: center;
  padding-bottom: 3em;
}

.section-about {
  margin-top: 6em;
}
 
.section-footer {
  display: block;
  width: 100%;
  padding: 4em 0;
  background: #8E1B39;
  .logo {
    display: block;
    margin: 0 auto;
    height: 200px;
    width: 194px;
  }
  .line {
    margin: 1em auto 0 auto;
  }
  h1 {
    font-size: 1.8em;
    color: #ffffff;
    margin: 1.5em 0 0 0;
    padding: 0;
  }
  nav {
    display: block;
    padding: 2em 0;
    margin: 0 auto;
    text-align: center;
  }
  a,
  .author {
  display: inline-block;
  color: #ffffff;
  padding: 1em;
  margin: 0 auto 0.5em auto;
  transition: all 0.3s;
  border-radius: 0.2em;
  &:hover {
    background: rgba(255,255,255,0.2);
  }
  }
}


.section-challenge {
  margin: -3em auto 0 auto;
  z-index: 2;
  .challenge-container {
    display: block;
    position: relative;
    width: 100%;
    padding: 2em 0em;
    margin: 0em -2em 0em 0em;
    background: #ffffff;
    border-radius: 0.6em;
    box-shadow: 0px 2px 10px 2px rgb(116 145 141 / 60%);

    h1 {
      margin: 0;
      padding: 1em 0;
      font-size: 2.7em;
      line-height: 1.2em;
    }
  }


  h1 {
    margin: 0;
    padding: 1em 0;
    font-size: 1em;
    line-height: 1.2em;

    line-height: 2em;
    color: #000000;

    span.title-unleashhacks {
      display: block;
      font-weight: 900;
      font-size: 1.4em;
      
      color: #8E1B39;
      color: #74918D;
      margin: 0.5em 0 0 0;
    }
    span.title-forthe {
      display: none;
      font-style: italic;
      font-size: 1.2em;
      margin: 0.5em 0 0 0;
    }
    span.title-sdg {
      display: none;
      font-size: 1.5em;
      margin: 0 0 0.5em 0;
    }
    span.title-forthe,
    span.title-sdg {
      display: inline-block;
      font-style: italic;
      font-size: 0.6em;
      margin: 0 0 0 0;
    }
  }

  b {
    color: #8E1B39;
  }
  i > b {
    color: #000000;
  }
  a {
    transition: all 0.3s;
    border-bottom: 2px solid transparent;
    &:hover {
      border-bottom: 2px solid #8E1B39;
    }
  }
  .challenge-content {
    display: flex;
    flex-direction: column;
    line-height: 2em;
    column-rule-style: solid;
    column-rule-color: rgba(0,0,0,0.1);

    .challenge-column {
      display: block;
      width: 100%;
      padding: 0em 2em;
      &:first-child { 
        border-right: 1px solid rgba(0,0,0,0.1);
      }
      & > h1:first-child {
        margin: 0;
      }
      img {
        width: 100%;
      }
    }

    .ingress {
      background: #4094CF;
      background: #bdd9d5;
      padding: 1em;
      border-radius: 0.5em;
      margin: 0 0 1em 0;
    }
    ul {
      margin: 0.5em 0 1em 0;
    }
    p + p {
      margin-top: 1em;
    }

    h1 {
      margin: 0;
      padding: 0 0 0.5em 0;
      font-size: 2.7em;
      line-height: 1.2em;
    }
    h2 {
      margin: 1em 0 0.5em 0;
      &:first-child {
        margin: 0em 0 0.5em 0;
      }
    }
    h3 {
      display: block;
      position: relative;
      padding: 0.5em 1em 0.5em 3em;
      background: #74918D;
      background: linear-gradient(165deg, #b52a4e 0%, #72112b 100%);
      border-radius: 0.2em;
      color: #ffffff;

      span {
        position: absolute;
        display: inline-block;
        width: 1.6em;
        height: 1.6em;
        left: 0.7em;
        margin: 0 1em 0 0;
        border-radius: 0.2em;
        background: #ffffff;
        border: 0.2em solid #fff;
        box-shadow: 0px 2px 5px rgba(0,0,0,0.4);
      }
      &.sdg3 {
        margin-top: 2em;
      }
      &.sdg3 span {
        background: #279b48;
      }
      &.sdg4 span {
        background: #c31f33;
      }
      &.sdg11 span {
        background: #f99d26;
      }
    }
  }
  .sdg-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 75%;
    margin: 2em auto 3em auto;
    border-radius: 0.4em;
    box-shadow: 0px 2px 10px 2px rgba(0,0,0,0.4);
    background: #ffffff;
    overflow: hidden;
    padding: 0.4em;
    transform: rotate(-1deg);
    transition: all 0.3s;
    a {
      display: block;
      width: 25%;
      padding: 0;
      margin: 0;
      &:hover {
        z-index: 2;
        transform: scale(1.1);
        box-shadow: 0px 2px 10px 2px rgba(0,0,0,0.4);
        border: none;
      }
      img {
        display: block;
        width: 100%;
        margin: 0;
        padding: 0;
      }
      &:first-child {
        border-radius: 0.3em 0 0 0.3em;
      }
      &:last-child {
        border-radius: 0 0.3em 0.3em 0;
      }
    }
  }
}


@media (max-width: 660px) { 
  .section-challenge {
    .sdg-container {
      width: 100% !important;
    }
  }
}
@media (max-width: 660px) { 
  .section-challenge {
    .challenge-container {
      display: block;
      position: relative;
      width: 100%;
      padding: 2em 0em;
      margin: 0em -2em 0em 0em;
      background: #ffffff;
      border-radius: 0.6em;
      box-shadow: 0px 2px 10px 2px rgb(116 145 141 / 60%);

      h1 {
        margin: 0;
        padding: 1em 0;
        font-size: 1.4em;
        line-height: 1.3em;

        line-height: 2em;
        color: #000000;

        span.title-unleashhacks {
          display: block;
          font-weight: 900;
          font-size: 3em;
          line-height: 0.85em;
          
          color: #8E1B39;
          color: #74918D;
          margin: 0.5em 0 0 0;
        }
        span.title-forthe {
          display: none;
          font-style: italic;
          font-size: 1em;
          margin: 1em 0 0 0;
          padding: 0;
        }
        span.title-sdg {
          display: none;
          font-size: 1em;
          margin: 1em 0 0.5em 0;
          padding: 0;
        }
        span.title-forthe,
        span.title-sdg {
          display: block;
          font-style: italic;
          font-size: 1em;
          line-height: 0.5em;
        }
      }
    }
  }
}


@media (min-width: 1000px) {
  .section-challenge {
    display: flex;
    width: 100%;
    z-index: 2;
    margin-top: -3em;

    .challenge-container {
      display: block;
      position: relative;
      width: 100%;
      padding: 2em 0em;
      margin: 0em -2em 0em 0em;
      background: #ffffff;
      border-radius: 0.6em;
      box-shadow: 0px 2px 10px 2px rgb(116 145 141 / 60%);

      h1 {
        margin: 0;
        padding: 1em 0;
        font-size: 1.4em;
        line-height: 1.2em;

        line-height: 2em;
        color: #000000;

        span.title-unleashhacks {
          display: block;
          font-weight: 900;
          font-size: 3em;
          
          color: #8E1B39;
          color: #74918D;
          margin: 0.5em 0 0 0;
        }
        span.title-forthe {
          display: none;
          font-style: italic;
          font-size: 1.2em;
          margin: 0.5em 0 0 0;
        }
        span.title-sdg {
          display: none;
          font-size: 1.5em;
          margin: 0 0 0.5em 0;
        }
        span.title-forthe,
        span.title-sdg {
          display: inline-block;
          font-style: italic;
          font-size: 1.2em;
          margin: 0.3em 0 0 0;
        }
      }
    }
    .challenge-content {
      display: flex;
      flex-direction: row;

      .challenge-column {
        display: block;
        width: 50%;
        padding: 0em 2em;
        &:first-child { 
          border-right: 1px solid rgba(0,0,0,0.1);
        }
        & > h1:first-child {
          margin: 0;
        }
      }

      .ingress {
        background: #4094CF;
        background: #bdd9d5;
        padding: 1em;
        border-radius: 0.5em;
        margin: 0 0 1em 0;
      }
      ul {
        margin: 0.5em 0 1em 0;
      }
      p + p {
        margin-top: 1em;
      }

      h1 {
        margin: 0;
        padding: 0 0 0.5em 0;
        font-size: 2.7em;
        line-height: 1.2em;
      }
      h2 {
        margin: 1em 0 0.5em 0;
      }
      h3 {
        display: block;
        position: relative;
        padding: 0.5em 1em 0.5em 3em;
        background: #74918D;
        background: linear-gradient(165deg, #b52a4e 0%, #72112b 100%);
        border-radius: 0.2em;
        color: #ffffff;

        span {
          position: absolute;
          display: inline-block;
          width: 1.6em;
          height: 1.6em;
          left: 0.7em;
          margin: 0 1em 0 0;
          border-radius: 0.2em;
          background: #ffffff;
          border: 0.2em solid #fff;
          box-shadow: 0px 2px 5px rgba(0,0,0,0.4);
        }
        &.sdg3 {
          margin-top: 2em;
        }
        &.sdg3 span {
          background: #279b48;
        }
        &.sdg4 span {
          background: #c31f33;
        }
        &.sdg11 span {
          background: #f99d26;
        }
      }
    }
    .sdg-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      width: 45%;
      width: 100%;
      margin: 2em auto 0 auto;
      border-radius: 0.4em;
      box-shadow: 0px 2px 10px 2px rgba(0,0,0,0.4);
      background: #ffffff;
      overflow: hidden;
      padding: 0.4em;
      transform: rotate(-1deg);
      transition: all 0.3s;
      a {
        display: block;
        width: 25%;
        padding: 0;
        margin: 0;
        &:hover {
          z-index: 3;
          transform: scale(1.1);
          box-shadow: 0px 2px 10px 2px rgba(0,0,0,0.4);
          border: none;
        }
        img {
          display: block;
          width: 100%;
          margin: 0;
          padding: 0;
        }
        &:first-child {
          border-radius: 0.3em 0 0 0.3em;
        }
        &:last-child {
          border-radius: 0 0.3em 0.3em 0;
        }
      }
    }
  }
}

@media (max-width: 900px) {
.section-about {
  margin-top: 13em;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  z-index: 2;

  p > b {
    white-space: nowrap;
  }

  .section-hacks {
    background: url(../assets/images/marketing/AlovqwwU.jpeg) no-repeat;
    background-size: cover;
  }
  .section-plus {
    background: url(../assets//images/marketing/557A4203.jpeg) no-repeat;
    background-size: cover;
  }
  .section-community,
  .section-hacks,
  .section-innovation-lab,
  .section-plus {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;

    h1 {
      display: block;
      position: absolute;
      top: -4em;
      left: 0;
      right: 0;
      background: #4094CF;
      color: #fff;
      font-size: 1.8em;
      z-index: 2;
      padding: 1em;
      line-height: 1.4em;

      width: 100%;
    }
    .icon-detail {
      display: block;
      height: 8px;
      margin: 0em 0em 2em 0em;
      width: 80px;
      background: url(../assets/images/dark_line.svg) no-repeat left;
    }
  }
  .section-hacks,
  .section-plus {
    .grid-2 {
      background: rgba(0,0,0,0.8) !important;
      color: #ffffff;
    }
  }
  .section-plus {
    .grid-2 {
      padding: 4.5em 4em 4em 4em;
    }
  }
  .grid-1{
    display: block;
    width: 100%;
    max-width: 100%;
    background: transparent url() no-repeat;
    background-size: cover;

    width: 100%;
  }
  .grid-2 {
    display: block;
    width: 100%;
    height: 100%;
    padding: 4.5em 4em 11em 4em;
    line-height: 1.55em;
    background: #ffffff;

    width: 100%;

      h2 {
        padding: 0 0 1em 0;
        line-height: 1.4em;
      }
      p {
        font-size: 1.2em;
      }
      a {
        transition: all 0.3s;
        border-bottom: 0.2em solid transparent;
        &:hover {
          border-bottom: 0.2em solid #8E1B39;
        }
      }
    }
    .image-community {
      background: url(https://unleash.org/wp-content/uploads/2020/05/SHM03695-760x505.jpg) no-repeat;
      background-size: cover;
    }
    .image-innovation-lab {
      background: url(https://unleash.org/wp-content/uploads/2020/05/SHM03695-760x505.jpg) no-repeat;
      background-size: cover;
    }
  }
}

@media (min-width: 901px) {
  .section-about {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    z-index: 2;

    p > b {
      white-space: nowrap;
    }
  
    .section-hacks {
      background: url(../assets/images/marketing/557A4203.jpeg) no-repeat;
      background-size: cover;
    }
    .section-plus {
      //../assets//images/marketing/qLuchfpU.jpeg
      background: url(../assets//images/marketing/PObBEOQQ.jpeg) no-repeat left;
      background-size: cover;
    }
    .section-community,
    .section-hacks,
    .section-innovation-lab,
    .section-plus {
      position: relative;
      display: flex;
      flex-directioN: row;
      width: 100%;
      height: auto;
  
      h1 {
        display: block;
        position: absolute;
        border-radius: 100em;
        background: #4094CF;
        color: #fff;
        font-size: 1.2em;
        z-index: 2;
  
        width: 8em;
        height: 8em;
        padding: 2.9em 0 0 0;
        top: 50%;
        left: 40%;
        margin: -4em 0 0 -4em;
      }
      .icon-detail {
        display: block;
        height: 8px;
        margin: 0em 0em 2em 0em;
        width: 80px;
        background: url(../assets/images/dark_line.svg) no-repeat left;
      }
    }
    .section-hacks,
    .section-plus {
      .grid-2 {
        background: rgba(0,0,0,0.8) !important;
        color: #ffffff;
      }
    }
    .grid-1{
      display: block;
      width: 40%;
      max-width: 100%;
      min-height: 400px;
      background: transparent url() no-repeat;
      background-size: cover;
    }
    .grid-2 {
      display: block;
      width: 60%;
      height: auto;
      padding: 5em 8em;
      line-height: 1.55em;
      background: #ffffff;
  
      h2 {
        padding: 0 0 1em 0;
        line-height: 1.4em;
      }
      p {
        font-size: 1.2em;
      }
      a {
        transition: all 0.3s;
        border-bottom: 0.2em solid transparent;
        &:hover {
          border-bottom: 0.2em solid #8E1B39;
        }
      }
    }
    .image-community {
      background: url(../assets/images/marketing/vaMuPou8.jpeg) no-repeat;
      background-size: cover;
    }
    .image-innovation-lab {
      background: url(../assets/images/marketing/qLuchfpU.jpeg) no-repeat;
      background-size: cover;
    }
  }
}
@media (min-width: 1320px) { 
  .section-community,
  .section-hacks,
  .section-innovation-lab,
  .section-plus {
    .grid-2 {
      padding: 5em 20% 5em 8em;
    }
  }
}






.events {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;

  .label {
    z-index: 3;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    font-weight: 700;
    //transform: rotate(-2deg);
    background: #ffffff;
    color: #ffffff;
    padding: 0.5em 1em;
    border-radius: 0 0.5em 0 0.5em;

    &.label-upcoming {
      background: #4094CF;
    }
    &.label-optional {
      background: #F2962A;
    }
    &.label-closed {
      background: #74918D;
    }
  }

  .event {
    display: flex;
    flex-direction: row;
    border-radius: 0.6em;
    background: rgba(255, 255, 255, 0.5);
    background: #ffffff;
    box-shadow: 0px 2px 10px 2px rgb(116, 145, 141, 60%);
    margin: 0 0 0.5em 0;
    backdrop-filter: blur(20px);
    transition: all 0.3s;
    &.active {
      background: rgba(250,250,250,1);
      background: #ffffff;
      //transform: scale(1.03);
    }
    &.inactive {
      opacity: 0.25;
      background: #3C4C3C;
      color: #ffffff;
      box-shadow: inset 0px 0px 10px 2px rgb(0,0,0, 60%);
      .date {
        background: transparent;
      }
    }
    &.event-special {
      .date {
        background: linear-gradient(165deg, #4094CF 0%, #2D4C83 100%);
      }
    }

    .date {
      display: block;
      position: relative;
      width: 8em;
      min-width: 8em;
      background: linear-gradient(165deg, #b52a4e 0%, #72112b 100%);
      color: #ffffff;
      text-align: left;
      border-radius: 0.6em 0 0 0.6em;
      .date-center {
        position: relative;
        padding: 1em;
      }
      .number {
        display: block;
        font-size: 1.8em;
        padding: 0;
        margin: 0;
        font-weight: 700;
      }
      .month {
        font-size: 0.8em;
        text-transform: uppercase;
        letter-spacing: 0.2em;
        font-weight: 300;
      }
    }

    .activity {
      display: block;
      position: relative;
      padding: 1em;
      width: 100%;

      h3 {
        font-size: 1.8em;
      }
      p {
        font-size: 1.1em;
      }
    }
  }
}


p span.time-of-activity {
  display: inline-block;
  background: rgba(150,150,150,0.2);
  padding: 0 0.1em;
  margin: 0 0.5em 0 0;
  border-radius: 0.2em;
  border: 1px solid #ccc;
}


iframe {
  margin-top: 1em;
}

.image-preloader {
  display: none;
}

.video-container {
  overflow: hidden;
  position: relative;
  width:100%;
  margin: 1em 0 2em 0;
}
.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: '';
}
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#slideshow {
  #new-player.embed #player-toolbar {
    background: #ffffff !important;
  }
}

.introduction a,
.navigation,
.card {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; 
  }


@media (min-width: 1400px) {
  #section-facilitators,
  #section-experts,
  #section-organizers {
    .container {
      width: 100%;
      max-width: 1400px;
    }
  }
 }


 @media (max-width: 940px) {
  .section-partners {
    z-index: 1;
    margin-top: -1em;
 
    .partners-container {
     display: block;
     position: relative;
     width: 100%;
     padding: 2em 2em;
     margin: 0em -2em 0em 0em;
 
     background: rgba(0,0,0,0.8);
     background: #8E1B39;
     border-radius: 0 0 0.6em 0.6em;
     border-radius: 0.6em;
     //box-shadow: rgb(116 145 141 / 60%) 0px 2px 10px 2px;
     box-shadow: 0 2px 10px 2px rgb(116 145 141 / 60%);
     h2 {
       font-size: 1em;
       text-transform: uppercase;
       letter-spacing: 4px;
       text-align: center;
       margin: 0 0 1.5em 0;
       color: #ffffff;
       font-weight: 600;
 
     }
     .partner-box {
       display: flex;
       flex-direction: column;
       flex-wrap: nowrap;
       justify-content: stretch;
       align-content: center;
       a {
         display: block;
         width: 100%;
         max-height: 3.5em;
         margin: 0 auto 1.5em auto;
         &:last-child {
          margin: 0 auto 0 auto;
         }
       }
       img {
         display: block;
         max-width: 18em;
         height: auto;
         max-height: 4em;
         margin: 0 auto;
         filter: grayscale(100%);
         filter: brightness(0) invert(1);
       }
     }
    }
  }
}

 @media (min-width: 941px) {
  .section-partners {
    z-index: 1;
    margin-top: -1em;
 
    .partners-container {
     display: block;
     position: relative;
     width: 100%;
     padding: 2em 2em;
     margin: 0em -2em 0em 0em;
 
     background: rgba(0,0,0,0.8);
     background: #8E1B39;
     border-radius: 0 0 0.6em 0.6em;
     border-radius: 0.6em;
     //box-shadow: rgb(116 145 141 / 60%) 0px 2px 10px 2px;
     box-shadow: 0 2px 10px 2px rgb(116 145 141 / 60%);
     h2 {
       font-size: 1em;
       text-transform: uppercase;
       letter-spacing: 4px;
       text-align: center;
       margin: 0 0 1.5em 0;
       color: #ffffff;
       font-weight: 600;
 
     }
     .partner-box {
       display: flex;
       flex-direction: row;
       flex-wrap: nowrap;
       justify-content: stretch;
       align-content: center;
       a {
         display: block;
         width: 33.333%;
         margin: 0 auto;
       }
       img {
         display: block;
         max-width: 18em;
         height: auto;
         max-height: 3.5em;
         margin: 0 auto;
         filter: grayscale(100%);
         filter: brightness(0) invert(1);
       }
     }
    }
  }
}


