
$particleSize: 20vmin;
$animationDuration: 6s;
$amount: 20;
.background-animation {
    position: relative;
    display: block;
    z-index: 0;
    height:100%;
    width: 100%;
}
.background-animation > span {
    pointer-events: none;
    width: $particleSize;
    height: $particleSize;
    border-radius: $particleSize;
    backface-visibility: hidden;
    position: absolute;
    animation-name: move;
    animation-duration: $animationDuration;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    $colors: (
        #8E1B39,
        #e9ca91,
        #3C4C3C,
        #dacbaf,
        #2D4C83
    );
    @for $i from 1 through $amount {
        &:nth-child(#{$i}) {
            color: nth($colors, random(length($colors)));
            top: random(100) * 1%;
            left: random(100) * 1%;
            animation-duration: (random($animationDuration * 10) / 10) * 1s + 10s;
            animation-delay: random(($animationDuration + 10s) * 10) / 10 * -1s;
            transform-origin: (random(50) - 25) * 1vw (random(50) - 25) * 1vh;
            $blurRadius: (random() + 0.5) * $particleSize * 0.5;
            $x: if(random() > 0.5, -1, 1);
            box-shadow: ($particleSize * 2 * $x) 0 $blurRadius currentColor;
        }
    }
}

@keyframes move {
    100% {
        transform: translate3d(0, 0, 1px) rotate(360deg);
    }
}